import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import PRButton from "../PRButton/PRButton";
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRImageSlider.scss";
import { RightArrowIcon, LeftArrowIcon } from "../../PRIcon/Icons";

export interface PRImageSliderProps {
  data: {
    Items?: Array<{
      Src: string;
      AltText: string;
      Date: string;
      Copy: string;
      CtaLabel: string;
      Url: string;
      key?: string;
    }>;
  };
  width: string;
}

export interface PRImageSliderState {
  activeIndex: number;
}

class PRImageSlider extends React.Component<
  PRImageSliderProps,
  PRImageSliderState
  > {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  animating = false;

  myDiv = null;

  node = null;

  headingElements = [];

  componentDidMount() {
    setTimeout(() => {
      this.props.data.Items.forEach((item, index) => {
        this.addUnderlineToHeading(this.headingElements[index], item.AltText);
      });
    });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.props.data.Items.forEach((item, index) => {
        this.addUnderlineToHeading(this.headingElements[index], item.AltText);
      });
    });
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.data.Items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.data.Items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  carouselNextButton = () => {
    return (
      <div className="carousel-control-next" onClick={this.next}>
        <PRIcon>
          <RightArrowIcon />
        </PRIcon>
      </div>
    );
  };

  carouselPrevButton = () => {
    return (
      <div className="carousel-control-prev" onClick={this.previous}>
        <PRIcon>
          <LeftArrowIcon />
        </PRIcon>
      </div>
    );
  };

  getCTA = (label: string, link: string) => {
    return (
      <div className="cta-container">
        <PRButton data={{ type: "secondary", link: link }}>{label || "Read More"}</PRButton>
      </div>
    );
  };

  addUnderlineToHeading = (element, text) => {
    var underline = "<span class=\"underline\"></span>";
    var words = text.split(" ");
    var lastWords = [];
    element.innerHTML = words[0];
    var height = element.offsetHeight;

    for (var i = 1; i < words.length; i++) {
      element.innerHTML += " " + words[i];

      if (element.offsetHeight > height) {
        height = element.offsetHeight;
        // (i-1) is the index of the word before the text wraps
        lastWords.push(i - 1);
      }
    }

    var lastWordOfFirstLine = lastWords.length ? lastWords[0] : words.length - 1;
    var firstLine = words.slice(0, lastWordOfFirstLine + 1).join(" ");
    var otherLines = words.slice(lastWordOfFirstLine + 1).join(" ");
    element.innerHTML = `<span class="first-line">${firstLine}</span>${underline}<span class="other-lines">${otherLines}</span>`;
  };

  render() {
    const { activeIndex } = this.state;
    const { width } = this.props;

    const slides = this.props.data.Items.map((item, $index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={"carousel-item-image-" + $index}
        >
          <div className="carousel-item-image" style={{ backgroundImage: "url(" + item.Src + ")" }} />
          <div className="carousel-text-container">
            <div className="carousel-text">
              <div className="carousel-text-inner">
                <div className="carousel-text-heading" ref={node => (this.headingElements[$index] = node)}></div>
                  <div className="carousel-text-copy">
                    <div dangerouslySetInnerHTML={{ __html: item.AltText }} /> 
                    <div dangerouslySetInnerHTML={{ __html: item.Copy }} />
                  </div>
                {this.getCTA(item.CtaLabel, item.Url)}
              </div>

            </div>
          </div>
        </CarouselItem>
      );
    });

    let carouselClass = "pr-image-slider " + width;
    this.props.data.Items.map(item => {
      let itemKey: string = "";
      itemKey += Object.keys(item).map((key: string) => {
        if (item[key]) {
          return item[key].split("").reduce(function (a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
          }, 0);
        }
      });
      item.key = itemKey;
    });
    return (
      <div ref={node => (this.node = node)}>
        <Carousel
          className={carouselClass}
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.props.data.Items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          {this.carouselPrevButton()}
          {this.carouselNextButton()}
        </Carousel>
      </div>
    );
  }
}

export default withWidth()(PRImageSlider);
